/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:1587ea38-7214-4711-8bbe-64a478ea88a7",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_wzy8L9AkF",
    "aws_user_pools_web_client_id": "5ntl945gld8igcogtvbsa7er3a",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qunv5jemgl.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "SystemsAPI",
            "endpoint": "https://lj8grd7m89.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "PersonsAPI",
            "endpoint": "https://02jev60z9b.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "centersAPI",
            "endpoint": "https://7n2fk5znhl.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "SuppliersAPI",
            "endpoint": "https://s0aekzky0i.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "SupplierPersonsAPI",
            "endpoint": "https://qzjntey582.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "oracleDetailSites-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "Systems-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "PersonsTable-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "centersTable-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "OraRecorderLog-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "SuppliersTable-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "SupplierPersonsTable-prod",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "dgmc-oracledetails-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d329lgp5hbtqav.cloudfront.net"
};


export default awsmobile;
